module.exports = {
    'open': { name:'Otwarty', color:'light-green accent-4'},
    'waiting_customer_approval': { name:'Oczekuje na akceptację klienta', color:'light-blue accent-4'},
    'waiting_designer_change': { name:'Oczekuje zmiany przez grafika', color:'blue accent-4'},
    'customer_approved': { name:'Zaakceptowany przez klienta', color:'teal accent-4'},

    'print_ready': { name:'Gotowy do druku', color:'blue accent-4'},
    'printed': { name:'Wydrukowany', color:'light-blue darken-2'},
    'canceled': { name:'Anulowany', color:'red lighten-2'},
}
