<template>
    <v-card>
        <v-toolbar dense color="primary" flat>
            <v-toolbar-title class="white--text">Dziękujemy za złożenie zamówienia</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pa-4">
            Potwierdziamy przyjęcie Twojego zamówienia {{ order.code }}<br/>
            Po wciśnięciu "OK" zostaniesz przeniesiony do panelu zamówienia, w którym możesz je opłacić.
        </v-card-text>
            
        <v-card-actions class="pt-0">
            <v-spacer></v-spacer>
            <v-btn color="primary darken-1" text @click.native="agree">OK</v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
export default {
    props: {
        order: Object
    },
    data: () => ({
    }),
    created() {
    },
    methods: {
        agree() {
            this.$emit('close', true);
        },
        
    }
}
</script>