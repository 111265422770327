<template>
    <v-card elevation="1" class="mb-5 mt-3 left-bordered-primary" v-if="tableProjects.length > 0">    
        <v-data-table
            :loading="loading"
            :headers="headers"
            :items="tableProjects"
            :footer-props="{
                'items-per-page-options': [10, 20, 30, 40, -1]
            }"
            class="elevation-1"
        >   
            <template v-slot:top>
                <v-toolbar flat color="white" dense>
                    <v-toolbar-title class="black--text">Projekty powiązane z Twoim zamówieniem</v-toolbar-title><br/>
                </v-toolbar>
                <p class="text-left ml-4 text-caption">Poniżej projekty</p>
                <v-divider ></v-divider>
            </template>
            <template v-slot:item.orderPosition="{ item }">
                <span>{{ item.productName }}</span>
                <br/>
                <span v-for="variant in item.variantNames" :key="variant" class="caption">
                    {{ variant }} /
                </span>
            </template>
            <template v-slot:item.status="{ item }">
                <span v-if="item.project.status">
                    <v-chip class="white--text"  x-small :color=projectStatuses[item.project.status].color>
                        {{ projectStatuses[item.project.status].name}}
                    </v-chip> 
                </span>
                <span v-else>
                    Brak statusu
                </span>
                <br/>
                <span class="text-caption gray--text" v-if=item.project.statusDate>{{item.project.statusDate  | moment("DD/MM/YY HH:mm")}}</span>
            </template>
            <template v-slot:item.projectLink="{ item }">
                <span v-if="item.adminPanelUrl" style="cursor:pointer;">
                    <v-chip small outlined  color="purple lighten-2">
                        <a target="_blank" :href="item.projectLink">
                            Panel projektu
                            <v-icon color="purple lighten-2" dark right > mdi-link </v-icon>
                        </a>
                    </v-chip> 
                </span>
            </template>
        </v-data-table>
    </v-card>
</template>
<script>
import Api from '../../CustomerApi';
import projectStatuses from '../../project-status-map-data.js';

export default {
    props: {
        orderContents:Array,
    },
    components: {
        
    },
    data () {
        return {
            loading:false,
            headers:[
                { text: 'Nazwa produktu', value: 'orderPosition',  sortable: false  },
                { text: 'Kod', value: 'project.code',  sortable: false  },
                { text: 'Status', value: 'status',  sortable: false  },
                { text: 'Link do projektu', value: 'projectLink',  sortable: false  },
            ],
            projects:[],
            projectStatuses: projectStatuses,

        }
    },
    created: async function() {
        this.loading = true;
        try {
            this.projects = await Api.designer.getProjects();
        } catch (error) {
            console.log(error);
        }
        this.loading = false;
    },
    destroyed: function() {
    },
    computed: {
        tableProjects() {
            return this.projects.filter(project => {
                let idx = this.orderContents.map(o => o._id).indexOf(project.orderPosition);
                return idx != -1;
            }).map(project => {
                let orderPos = this.orderContents.find(o => o._id == project.orderPosition);
                return Object.assign({
                    productName: orderPos.name,
                    variantNames: orderPos.variants.filter(v => project.variants.indexOf(v._id) != -1).map(v => v.name),
                    code: project.code,
                }, project);
            });
        }
    },
    methods: {       
    }
}
</script>
