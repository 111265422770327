<template>
    <v-dialog v-model="show" max-width="600" persistent>
        <v-card>
            <v-toolbar dark color="primary" dense flat>
                <v-toolbar-title class="white--text">Składanie zamówienia</v-toolbar-title>
                <v-progress-linear
                    :active="loading"
                    :indeterminate="loading"
                    absolute
                    bottom
                    color="secondary"
                ></v-progress-linear>
            </v-toolbar>
            <v-card-text class="mt-2">
                <v-form v-model="valid">
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    label="Imię i nazwisko*"
                                    v-model="name"
                                    :rules="nameRules"
                                    required
                                    outlined
                                    dense
                                    hide-details
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    label="Email*"
                                    v-model="email"
                                    :rules="emailRules"
                                    required
                                    outlined
                                    dense
                                    hide-details
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="phone"
                                    label="Telefon kontaktowy*"
                                    :rules="phoneRules"
                                    outlined
                                    dense
                                    hide-details
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <span class="primary--text">Zgody na przetwarzanie danych</span>
                                <v-row>
                                    <v-col cols="1" class="pb-0">
                                        <v-checkbox
                                            class="pt-0"
                                            v-model="zgoda"
                                        ></v-checkbox>
                                    </v-col>
                                    <v-col cols="11" class="pb-0">
                                        <div class="mt-4" style=font-size:12px;>
                                            Niniejszym wyrażam zgodę na przetwarzanie moich danych osobowych przez NewNetwork.pl Szymon Jędrzejczak z siedzibą w Goleniów (72-100) przy ul. Cicha 10/1, zarejestrowaną w Goleniowie, nr NIP: 9552016771, na potrzeby i w zakresie niezbędnym do korzystania przeze mnie z usług świadczonych przez sklep Amelia-Wedding.pl, w tym na przekazywanie moich danych osobowych podmiotom trzecim, przy pomocy których Amelia-Wedding.pl świadczy usługi związane z realizacją zamówień oraz obsługą płatności. Więcej informacji na temat RODO znajdą Państwo tutaj: 
                                            <a href="https://amelia-wedding.pl/content/rodo.html">RODO</a> *
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-col>
                                                                        
                            <v-row>
                                <v-col cols="1" class="pb-0" >
                                    <v-checkbox
                                        class="pt-0"
                                        v-model="rabatCodes"
                                    ></v-checkbox>
                                </v-col>
                                <v-col cols="11" class="pb-0">
                                    <div class="mt-4" style=font-size:12px;>Wyrażam zgodę na otrzymywanie kodów rabatowych (wyłącznie od Amelia-Wedding.pl)</div>
                                </v-col>
                                <!-- <v-col cols="1" class="pb-0 pt-0">
                                    <v-checkbox
                                        class="pt-0"
                                        v-model="newsletter"
                                    ></v-checkbox>
                                </v-col>
                                <v-col cols="11" class="pb-0 pt-0">
                                    <div class="mt-4" style=font-size:12px;> Zapisz się do newslettera (Możesz zrezygnować w każdej chwili. )</div>
                                </v-col> -->
                            </v-row>
                                      
                            
                        </v-row>
                    </v-container>
                </v-form>
                <small class="primary--text">* Pola wymagane</small>
            </v-card-text>
            
            <v-card-actions class="pt-0">
                <v-spacer></v-spacer>
                <v-btn color="primary darken-1" text :loading="loading" @click.native="agree">Złóż zamówienie</v-btn>
                <v-btn color="grey" text :loading="loading" @click.native="cancel">Anuluj</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import Api from '../../CustomerApi';
import OrderConfirmDialog from './OrderConfirmDialog.vue';
import config from '../../config';
import Utils from '../../Utils';

export default {
    props: {
        value: Boolean,
        voucherCode: String
    },
    data() {
        return {
            loading: false,
            valid: false,
            name: '',
            email: '',
            phone: '',
            nameRules: [
                v => !!v || 'Prosimy o podanie imienia i nazwiska',
                v => v.trim().split(' ').length > 1 || 'Prosimy o podanie imienia i nazwiska',
            ],
            emailRules: [
                v => !!v || 'Prosimy o podanie poprawnego adresu email',
                v => /.+@.+/.test(v) || 'Podany adres nie jest poprawny',
            ],
            phoneRules: [
                v => !!v || 'Prosimy o podanie numeru telefonu',
            ],
            zgoda:false,
            rabatCodes:true,
            newsletter:false,
        }
    },
    computed: {
        show: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value);
            }
        }
    },

    methods: {
        async agree() {
            // this.show = false;
            if(!this.zgoda) {
                this.$toast.error("Prosimy zakceptować regulamin");
                return;
            }
            if(this.name.length == 0 || this.email.length == 0 || this.phone.length == 0 ) {
                this.$toast.error("Prosimy wypełnić pola wymagane w celu złożenia zamówienia");
                return;
            }
            this.loading = true;

            try {
                let order = await Api.order.update({customer: {
                    name: this.name,
                    email: this.email,
                    phone: this.phone
                }});

                let d = {};
                if (this.voucherCode) d.voucherCode = this.voucherCode;
                order = await Api.order.place(d);
                let p = order.prices || {};
                let revenue = p.products + (p.delivery || 0) - p.discounts + p.express;
                let orderContents = await Api.order.getContent();

                if (window.gtag) {
                    console.log(`Sending Gtag`);

                    window.gtag('set', 'user_data', {
                        "email": this.email
                    });

                    // google ads
                    window.gtag('event', 'conversion', {
                        'send_to': 'AW-10998225906/vJsNCJ287uIDEPK3rvwo',
                        'value': revenue,
                        'currency': 'PLN',
                        'transaction_id': order.code
                    });

                    // google analytics
                    window.gtag('event', 'purchase', {
                        transaction_id: order.code,
                        value: revenue,
                        currency: "PLN",
                        shipping: (p.delivery || 0),
                        items: orderContents.map(orderPos => {
                            return {
                                item_id: orderPos.product.id,
                                item_name: orderPos.name,
                                index: orderContents.indexOf(orderPos),
                                price: orderPos.price,
                                quantity: orderPos.qty
                            };
                        })
                    });

                }

                if (window.wph) {
                    console.log(`Sending wph Purchase`);
                    window.wph('track', 'Purchase', {
                        transaction_id: order.code,
                        value: revenue/1.23,
                        value_gross: revenue,
                        shipping_cost: p.delivery || 0,
                        discount_code: this.voucherCode || null,
                        contents: orderContents.map(orderPos => {
                            return {
                                id: orderPos.product.id,
                                name: orderPos.name,
                                price: orderPos.price,
                                quantity: orderPos.qty
                            };
                        })
                    });
                }

                if (window.fbq) {
                    console.log(`Sending FB Purchase`);
                    window.fbq('track', 'Purchase', {
                        contents: orderContents.map(orderPos => orderPos.name),
                        num_items: orderContents.length,
                        value: revenue,
                        currency: 'PLN' 
                    });
                }

                let conf = await this.$modal.show(OrderConfirmDialog, {order: {}});
                this.loading = false;
                this.show = false;
                localStorage.removeItem(config.CUSTOMER_LS_KEY);
                return this.$router.push({ name: 'order', params: {orderUUID: order.uuid} });
            } catch (error) {
                console.log(error)
                this.$toast.error("Niestety wystąpił błąd podczas tworzenia zamówienia");
            }

            this.loading = false;
        },
        cancel() {
            this.show = false;
        }
    }
}
</script>