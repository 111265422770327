<template>
    <div>
        <v-row class="text-center mt-1">
            <v-col cols="12" lg="3" md="4" sm="5" class="text-left">
                <v-card style="min-height: 380px;">
                    <div class="pa-3">
                        <v-img height="150px" src="../../assets/add-to-cart.png" contain />
                    </div>
                    <!-- <v-card-title>Wszystkie produkty</v-card-title> -->

                    <!-- <v-card-text class="text--primary">
                        <div>Planujesz zakup większej ilości produktów? Dodaj je teraz do koszyka i złóż jedno zamówienie</div>
                    </v-card-text> -->

                    <v-card-actions>
                        <v-btn color="primary" text class="mt-4" @click="backToShop">Dodaj kolejny produkt</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>

            <v-col cols="12" lg="3" md="4" sm="5" class="text-left">
                <v-card style="min-height: 380px;">
                    <div class="pa-3">
                        <v-img height="150px" src="../../assets/pallet.png" contain />
                    </div>
                    <v-card-title>Ilości</v-card-title>

                    <v-card-text class="text--primary">
                        <p>Lista gości może się zmienić... rozumiemy to doskonale. Ale nie martw się ilości produktów możesz <strong>dowolnie zmieniać</strong> po złożeniu zamówienia</p>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="12" lg="3" md="4" sm="5" class="text-left">
                <v-card style="min-height: 380px;">
                    <div class="pa-3">
                        <v-img height="150px" src="../../assets/archive.png" contain />
                    </div>
                    <v-card-title>Treści, listy gości, szczegóły</v-card-title>

                    <v-card-text class="text--primary">
                        <p>Wszystkie potrzebne szczegóły możesz podać po złożeniu zamówienia. Nie musisz się spieszyć</p>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="12" lg="3" md="4" sm="5" class="text-left">
                <v-card style="min-height: 380px;">
                    <div class="pa-3">
                        <v-img height="150px" src="../../assets/payment-method.png" contain />
                    </div>
                    <v-card-title>Płatność</v-card-title>

                    <v-card-text class="text--primary">
                        <p>Płatności możesz dokonać po zaakceptowaniu wszystkich projektów, jakie Ci wyślemy.</p>
                        <p>Obsługujemy szybkie płatności internetowe jak i zwykłe przelewy bankowe</p>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: "CartExtraInfo",

    data() {
        return {
            backUrl: null
        }
    },

    created() {
        this.backUrl = localStorage.getItem("back-to-url");
    },

    methods: {
        backToShop() {
            window.location.href = this.backUrl;
        }
    }
}
</script>

<style>

</style>